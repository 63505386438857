
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../style/style.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;



const EditStatus = ({ onClose,ord }) => {
    const [cadastros, setCadastros] = useState([]);
    const [data, setData] = useState({
        "id_order": '',
        "order_text": '',
        "date_os": '',
        "data_start": '',
        "latStart": '',
        "longStart": '',
        "dateFinish": '',
        "latFinish": '',
        "longFinish": '',
        "farmName": '',
        "nameResponsible": '',
        "registerFarm": '',
        "county": '',
        "federativeUnit": '',
        "farmAndress": '',
        "telContact": '',
        "emailContact": '',
        "supervisorName": '',
        "supervisorRegister": '',
        "supervisorCargo": '',
        "user_id": '',
        "name": '',
        "data_update": '',
        "data_mogai": ''
      });
      const parsedURL = localStorage.getItem('urlParsed');
      const token  = localStorage.getItem('token');

      useEffect(() => {
        fetchData();
      }, [ord]); 
    
      const fetchData = async () => {
        try {
          const response = await axios.get(`${parsedURL}get/lista_ordens_specific/site?orderNumber=${ord}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          
          if (response.data && response.data.length > 0) {
            setData(response.data[0]);
          }
        } catch (error) {
          console.error('Ocorreu um erro ao buscar os dados:', error);
        }
      };

      const fetchDownloadResume = (idOdr,textOdr) => {
        const url = `${parsedURL}get/download_pdf_parse/site?idOrder=${decodeURIComponent(idOdr)}`;
    
        console.log('Iniciando solicitação de download...');
        const toastError = document.getElementById('liveSucceful');
        const toastBody = toastError.querySelector('.toast-body');
        const toastBootstrap = new bootstrap.Toast(toastError);
    
        // Atualize o texto do toast com a mensagem de erro
        toastBody.textContent = 'Iniciando o a geração do relatorio';
        toastBootstrap.show();
        axios({
          method: 'GET',
          url: url,
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
    
          // Código anterior ...
    
          .then(response => {
            console.log('Resposta recebida:', response);
    
            // Extrair o nome do arquivo do cabeçalho content-disposition
            const contentDisposition = response.headers['content-disposition'];
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : `Resumo de Cubagem ${textOdr}.pdf`;
    
            // Criar um URL temporário para o blob de dados
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    
            // Criar um elemento de âncora para iniciar o download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = fileName;
    
            // Simular um clique no link para iniciar o download
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            // Liberar o URL temporário
            window.URL.revokeObjectURL(blobUrl);

            const toastError = document.getElementById('liveSucceful');
            const toastBody = toastError.querySelector('.toast-body');
            const toastBootstrap = new bootstrap.Toast(toastError);
        
            // Atualize o texto do toast com a mensagem de erro
            toastBody.textContent = 'Relatorio Gerado com sucesso!';
            toastBootstrap.show();
          })
          .catch(error => {
            const toastError = document.getElementById('liveError');
            const toastBody = toastError.querySelector('.toast-body');
            const toastBootstrap = new bootstrap.Toast(toastError);
        
            // Atualize o texto do toast com a mensagem de erro
            toastBody.textContent = 'Erro ao gerar o arquivo';
            toastBootstrap.show();
          
          });
    
      };

  return (
        <>
         <div class="modal fade" id="modalstatus" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 hfx" id="staticBackdropLabel">Dados do Status</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form class="row g-3">
      <p className='hfx'>Ordem de Serviço: {data.order_text}</p>
                  <div class="col-md-4">
                      <label for="razaosocial" class="form-label ">Ordem de Serviço</label>
                      <input type="text" name="farmName"class="form-control transparent-input" id="razaosocial"  value={data.order_text || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Nome da Fazenda</label>
                      <input type="text" name="nameResponsible" class="form-control transparent-input " id="nomefantasia"  value={data.farmName || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Data Inicial</label>
                      <input type="text" name="registerFarm" class="form-control transparent-input " id="nomefantasia" value={data.data_start || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="razaosocial" class="form-label ">Latitude Inicial</label>
                      <input type="text" name="county" class="form-control transparent-input" id="razaosocial" value={data.latStart || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Longitude inicial</label>
                      <input type="text" name="federativeUnit" class="form-control transparent-input " id="nomefantasia" value={data.longStart || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Data Final</label>
                      <input type="text" name="farmAndress" class="form-control transparent-input " id="nomefantasia" value={data.dateFinish || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="razaosocial" class="form-label ">Latitude final</label>
                      <input type="text" name="ie" class="form-control transparent-input" id="razaosocial" value={data.latFinish || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Longitude final</label>
                      <input type="text" name="ie" class="form-control transparent-input " id="nomefantasia" value={data.longFinish || ''} readOnly='true'/>
                  </div>
                  <div><hr class="divider bg-custom"/></div>
                  
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Acompanhante</label>
                      <input type="text" name="telContact" class="form-control transparent-input " id="nomefantasia" value={data.supervisorName || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Cargo</label>
                      <input type="text" name="emailContact"  class="form-control transparent-input " id="nomefantasia" value={data.supervisorCargo || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">CPF/RG</label>
                      <input type="text" name="date_os" class="form-control transparent-input " id="nomefantasia" value={data.supervisorRegister || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Data Update</label>
                      <input type="text" name="date_os" class="form-control transparent-input " id="nomefantasia" value={data.data_update || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Cadastro Mogai</label>
                      <input type="text" name="date_os" class="form-control transparent-input " id="nomefantasia" value={data.data_mogai || ''} readOnly='true'/>
                  </div>
                  <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-primary btn-lg" onClick={() => fetchDownloadResume(data.id_order,data.order_text)}><SaveAltIcon/> Gerar</button>
                  </div>
                  </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

        </>
  );
};

export default EditStatus;