import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../style/style.css";
import Logo_AGS from '../assets/images/AGS_L_BACK.png';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;


const ModalNav = () => {
    const navigate = useNavigate();
    const [showCadastro, setShowCadastro] = useState(false);
    const [showIntegrador, setIntegrador] = useState(false);

    useEffect(() => {
        const typeUser = parseInt(localStorage.getItem("typeUser"));

        if (!isNaN(typeUser)) {
            setShowCadastro(typeUser === 1608);
        }if(!isNaN(typeUser)){
            setIntegrador(typeUser === 1600);
        }else{}
    }, []);

    const HandleExitSystem = async (event) => {
        event.preventDefault();
        localStorage.removeItem('token');
        localStorage.clear();
        navigate('/login', { replace: true });

    }
    return (
        <div>
            <header>
                <nav className="nav-bar">
                    <div className="logo">
                        <img src={Logo_AGS} className="imagem-logo-navBar" alt="logo" />
                    </div>
                    <div className="nav-list">
                        <ul>
                            <li className="nav-item"><Link to="/home" className="nav-link">Home</Link></li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link">Ordem de Serviços</Link>
                                <div className="dropdown-menu">
                                <Link to="/cadastro_os" className="nav-link">Cadastro de OS</Link>
                                <Link to="/ordens_de_servico" className="nav-link">Lista de OS</Link>
                                </div>
                            </li>                        
                            <li className="nav-item"><Link onClick={HandleExitSystem} className="nav-link">Sair</Link></li>
                        </ul>
                        <div className="mobile-menu-icon">
                            <button><img className="icon" src="assets/img/menu_white_36dp.svg" alt="" /></button>
                        </div>
                    </div>
                </nav>
                <div className="mobile-menu">
                    <ul>
                        <li className="nav-item"><Link to="/" className="nav-link">Início</Link></li>
                        <li className="nav-item"><Link to="/projetos" className="nav-link">Projetos</Link></li>
                        <li className="nav-item"><Link to="/sobre" className="nav-link">Sobre</Link></li>
                    </ul>
                </div>
            </header>
        </div>
    );
};

export default ModalNav;
