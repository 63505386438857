import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../style/style.css";
import Logo_AGS from '../assets/images/AGS_L_BACK.png';
import Backgroud from '../assets/images/wallpaperflare.jpg';
import ModalNav from "../navbar";
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

const Home = () => {


    return (
        <section className="section_login">
            <div>
                <ModalNav></ModalNav>
            </div>
        </section>
    );
};

export default Home;
