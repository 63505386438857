
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../style/style.css';

import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

const ModalMogai = ({ onClose,ord,textOrd }) => {
  const toastTrigger = document.getElementById('liveToastBtn')
  const toastLiveExample = document.getElementById('liveToast')
  const [cadastros, setCadastros] = useState([]);
  const [data, setData] = useState(0);
  const [listagem, setListagem] = useState('');
  const [opcoesListagem,setOpcoesListagem] = useState([]);
  const [imagem, setImagem] = useState(null);
  const [cubagem,setCubagem] = useState(null)
  const parsedURL = localStorage.getItem('urlParsed');
  const token  = localStorage.getItem('token');

  useEffect(() => {
    fetchSilos();
    fetchData();
  }, [ord]);


  const fetchSilos = async () => {
    try {
      const response = await axios.get(`${parsedURL}get/silos_per_order/site?id_order=${ord}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setOpcoesListagem(response.data);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${parsedURL}get/lista_ordens_specific/site?orderNumber=${ord}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setData(response.data);
      console.log(response.data);
      console.log(data);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error);
    
  }};

  const handleImagemChange = (event) => {
    const arquivoSelecionado = event.target.files[0];
    setImagem(arquivoSelecionado);
  };

  const enviarFotoViaAPI = async () => {
    const identify_silo = listagem;
    const kg = cubagem;
    const url = `${parsedURL}post/photo_order_save_mogai?identify_silo=${identify_silo}&cubagem=${kg}&id_order=${ord}&id_type=7`;
  
    const formData = new FormData();
    formData.append('imagem', imagem);
  
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
      });
  

      const toastError = document.getElementById('liveSucceful');
      const toastBody = toastError.querySelector('.toast-body');
      const toastBootstrap = new bootstrap.Toast(toastError);
  
      // Atualize o texto do toast com a mensagem de erro
      toastBody.textContent = 'Envio da Silo Realizado com Sucesso!';
      toastBootstrap.show();

      // Lógica adicional após o envio da foto, se necessário
    } catch (error) {

      const toastError = document.getElementById('liveError');
      const toastBody = toastError.querySelector('.toast-body');
      const toastBootstrap = new bootstrap.Toast(toastError);
  
      // Atualize o texto do toast com a mensagem de erro
      toastBody.textContent = error.message || 'Erro desconhecido ao enviar a foto';
      toastBootstrap.show();

    }
  };

  const handleCubagem = (cub) => {
    setCubagem(cub);
  }


  return (
        <>
         <div class="modal fade" id="modalmogai" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 hfx" id="staticBackdropLabel">Status Mogai</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form class="row g-3">
                  <div class="col-md-6">
                      <label for="razaosocial" class="form-label ">Texto de Ordem</label>
                      <input type="text" class="form-control transparent-input"  readOnly="true" value={textOrd}/>
                  </div>
                  <div class="col-md-6">
                      <label for="nomefantasia" class="form-label">Nome da Fazenda</label>
                      <input type="text" class="form-control transparent-input" value={data[0]?.farmName || ''}/>
                  </div>
                  <div class="col-md-6">
                      <label for="nomefantasia" class="form-label">Silos</label>
                      <select className=' form-select transparent-input'
                            value={listagem}
                            onChange={(e) => setListagem(e.target.value)}
                            >
                           <option value="" disabled>Selecione uma opção</option>
                            {opcoesListagem.length > 0 &&
                            opcoesListagem.map((opcao, index) => (
                                <option key={index} value={opcao.identify_silo}>
                                {opcao.identify_silo}
                                </option>
                                    ))}
                            </select>
                  </div>
                  <div class="col-md-6">
                      <label for="razaosocial" class="form-label">Cubagem</label>
                      <input type="text" name="cubagem" class="form-control transparent-input" id="razaosocial"  value={cubagem} onChange={(e) => handleCubagem(e.target.value)}/>
                  </div>
                  <div class="col-md-6">
                  <label for="formFile" class="form-label">Insira seu Arquivo</label>
                    <input class="form-control" type="file" id="formFile" onChange={handleImagemChange}/>
                  </div>
                  </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success"  onClick={enviarFotoViaAPI}>Ok</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
      </div>
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveError" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-danger text-white">
      <strong class="me-auto">Erro</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-warning ">
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveSucceful" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-success text-white">
      <strong class="me-auto">Status</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-light ">
    </div>
  </div>
</div>
        </>
  );
};

export default ModalMogai;