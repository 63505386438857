import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ModalNav from "../navbar";
import '../style/style.css';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;


const Cad_Os = () => {
  const [data, setData] = useState([]);
  const [Users,setUser] = useState([]);
  const [selectUser, setSelectUser] = useState()
  const [orderId,setOrderId] = useState()
  
  const [order, setOrder] = useState(
    {
      farmName: '',
      nameResponsible: '',
      registerFarm: '',
      county: '',
      federativeUnit: '',
      farmAndress: '',
      telContact: '',
      emailContact: '',
      user_id: '',
      rci_rti: '',
      number_bayer: '',
      vol_declarete: ''
    }
    );
    const parsedURL = localStorage.getItem('urlParsed');
    const token  = localStorage.getItem('token');
    
    useEffect(() => {
        fetchUsers();
      }, []);


    const handleChange = (e) => {
        setOrder({
          ...order,
          [e.target.name]: e.target.value,
        });
      };
    
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    

    try {
      const url = `${parsedURL}post/criar_ordem_servico`;
      const selectCLI = selectUser;
      console.log(selectCLI);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization':  `Bearer ${token}`,
      };

      const response = await axios.post(
        url,
        {
          farmName: order.farmName,
          nameResponsible: order.nameResponsible,
          registerFarm: order.registerFarm,
          county: order.county,
          federativeUnit: order.federativeUnit,
          farmAndress: order.farmAndress,
          telContact: order.telContact,
          emailContact: order.emailContact,
          user_id: selectCLI,
          rci_rti: order.rci_rti,
          number_bayer: order.number_bayer,
          vol_declarete: order.vol_declarete
        }, { headers }
      );
      const data = response.data;
      setOrderId(data.id_order);
      const toastError = document.getElementById('liveSucceful');
      const toastBody = toastError.querySelector('.toast-body');
      const toastBootstrap = new bootstrap.Toast(toastError);
  
      // Atualize o texto do toast com a mensagem de erro
      toastBody.textContent = `Cadastro da OS ${data.id_order} realizado com sucesso!`;
      toastBootstrap.show();
    } catch (error) {
        console.error('Erro ao criar OS:', error);
        const toastError = document.getElementById('liveError');
        const toastBody = toastError.querySelector('.toast-body');
        const toastBootstrap = new bootstrap.Toast(toastError);
    
        // Atualize o texto do toast com a mensagem de erro
        toastBody.textContent = error.message || 'Erro desconhecido ao enviar a foto';
        toastBootstrap.show();
    }
    setSelectUser(0)
    setOrder(    {
      farmName: '',
      nameResponsible: '',
      registerFarm: '',
      county: '',
      federativeUnit: '',
      farmAndress: '',
      telContact: '',
      emailContact: '',
      user_id: '',
      rci_rti: '',
      number_bayer: '',
      volum_declarete: ''
    });
    

  };

  const fetchUsers = async () => {
    try {
        const response = await axios.get(`${parsedURL}get/lista_usuarios/site`, {
          headers: {
            'Accept': 'application/json',
            'Authorization':  `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Ocorreu um erro ao buscar os dados:', error);
      }
  };

  return (
        <>
        <ModalNav/>
        <div className="box-center">
        <legend className="text-dark">Cadastro Ordem de Serviço</legend>
        <form class="row g-3" onSubmit={handleSubmit}> 

    
                  <div class="col-md-6">
                      <label htmlFor="razaosocial" class="form-label hfx3 ">Fazenda</label>
                      <input type="text" class="form-control transparent-input" name="farmName" placeholder="Digite o nome da Fazenda" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-6">
                      <label htmlFor="nomefantasia" class="form-label hfx3">Agricultor</label>
                      <input type="text" class="form-control transparent-input " name="nameResponsible" placeholder="Digite o nome do agricultor" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="nomefantasia" class="form-label hfx3">CNPJ / CPF</label>
                      <input type="text" class="form-control transparent-input " name="registerFarm" placeholder="Digite o CNPJ / CPF" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-3">
                      <label htmlFor="razaosocial" class="form-label hfx3 ">Municipio</label>
                      <input type="text" class="form-control transparent-input" name="county" placeholder="Municipio da Fazenda" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-1">
                      <label htmlFor="nomefantasia" class="form-label hfx3">Estado</label>
                      <input type="text" class="form-control transparent-input " name="fedarativeUnit" placeholder="Estado Localizado a Fazenda" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="nomefantasia" class="form-label hfx3">Endereço</label>
                      <input type="text" class="form-control transparent-input " name="farmAndress" placeholder="Endereço Localizado a Fazenda" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="razaosocial" class="form-label hfx3 ">RTV/RTVI</label>
                      <input type="text" class="form-control transparent-input" name="rci_rti" placeholder="Informe o RTV / RTVI" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="nomefantasia" class="form-label hfx3">Volume Declarado</label>
                      <input type="text" class="form-control transparent-input " name="vol_declarete" placeholder="Volume Declarado" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="nomefantasia" class="form-label hfx3">N° Bayer</label>
                      <input type="text" class="form-control transparent-input " name="number_bayer" placeholder="Número da Bayer" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div><hr class="divider"/></div>
                  <legend className="text-dark">Contato</legend>
                  <div class="col-md-6">
                      <label htmlFor="nome" class="form-label hfx3 ">Usuário</label>
                      <select className='select_users_create form-select transparent-input'
                            index= {selectUser}
                            onChange={e => setSelectUser(e.target.selectedIndex)}
                            >
                            <option selected value="0">Selecione o usuario</option>
                            {Users.length > 0 &&
                                Users.map((user, index) => (
                                    <option key={index} value={user.id_user}>{user.name}</option>
                                ))
                            }
                            </select>
                  </div>
                  <div class="col-md-6">
                      <label htmlFor="nomefantasia" class="form-label hfx3">Telefone de contato</label>
                      <input type="text" class="form-control transparent-input " name="telContact" placeholder="Digite o telefone" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-md-4">
                      <label htmlFor="nomefantasia" class="form-label hfx3">E-mail</label>
                      <input type="text" class="form-control transparent-input " name="emailContact" placeholder="@email.com" autoComplete="off"  onChange={handleChange}/>
                  </div>
                  <div class="col-12">
                <button type="submit" class="btn btn-success">Cadastrar</button>
                <button type="reset" class="btn btn-warning mx-2">Limpar</button>
            </div>
        </form>
        </div>
        <div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveError" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-danger text-white">
      <strong class="me-auto">Erro ao Cadastrar</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-warning ">
    </div>
  </div>
</div>

<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveSucceful" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-success text-white">
      <strong class="me-auto">Cadastro Realizado</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-light ">
    </div>
  </div>
</div>
        </>
  );
};

export default Cad_Os;