import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridArrowUpwardIcon, GridSearchIcon } from "@mui/x-data-grid";
import axios from "axios";
import { Checkbox } from "@mui/material";
import ModalNav from "../navbar";
import { BiArrowToTop } from "react-icons/bi";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import '../style/style.css';
import Modal1 from "./lista_os_modal";
import ModalMogai from "../status_morgai/modal_mogai";
import EditStatus from "../edit_data/edit_status";
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;



const ListaOs = () => {
  const [data, setData] = useState([]);
  const [idOrder, setIdOrder] = useState('');
  const [textOrd, setTextOrd] = useState('');
  const parsedURL = localStorage.getItem('urlParsed');
  const token  = localStorage.getItem('token');

  const getConsultaOS = async () => {
    try {
      const response = await axios.get(`${parsedURL}get/lista_ordens/site`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDownloadResume = () => {
    const url = `${parsedURL}get/resumo_table/download`;

    console.log('Iniciando solicitação de download...');
    const toastError = document.getElementById('liveSucceful');
    const toastBody = toastError.querySelector('.toast-body');
    const toastBootstrap = new bootstrap.Toast(toastError);

    // Atualize o texto do toast com a mensagem de erro
    toastBody.textContent = 'Iniciando o a geração do relatorio';
    toastBootstrap.show();
    axios({
      method: 'GET',
      url: url,
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })

      // Código anterior ...

      .then(response => {
        console.log('Resposta recebida:', response);

        // Extrair o nome do arquivo do cabeçalho content-disposition
        const contentDisposition = response.headers['content-disposition'];
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        const fileName = matches && matches[1] ? matches[1].replace(/['"]/g, '') : `Resumo de Operacao - Cubagem.xlsx`;

        // Criar um URL temporário para o blob de dados
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Criar um elemento de âncora para iniciar o download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = fileName;

        // Simular um clique no link para iniciar o download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar o URL temporário
        window.URL.revokeObjectURL(blobUrl);

        const toastError = document.getElementById('liveSucceful');
        const toastBody = toastError.querySelector('.toast-body');
        const toastBootstrap = new bootstrap.Toast(toastError);
    
        // Atualize o texto do toast com a mensagem de erro
        toastBody.textContent = 'Relatorio Gerado com sucesso!';
        toastBootstrap.show();
      })
      .catch(error => {
        const toastError = document.getElementById('liveError');
        const toastBody = toastError.querySelector('.toast-body');
        const toastBootstrap = new bootstrap.Toast(toastError);
    
        // Atualize o texto do toast com a mensagem de erro
        toastBody.textContent = 'Erro ao gerar o arquivo';
        toastBootstrap.show();
      
      });

  };

  useEffect(() => {
    getConsultaOS();
  }, []);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id_order === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };
  const handleGerar = (id,ordText) => {
    setIdOrder(id);
    setTextOrd(ordText);
    console.log(`Botão "Ações" clicado para a linha com ID ${id}`);
  };
  const handleAnexar = (id,ordText) => {
    setIdOrder(id);
    setTextOrd(ordText);
    console.log(`Botão "Ações" clicado para a linha com ID ${id}`);
  };
  const handleEditOs = (id,ordText) => {
    setIdOrder(id);
    setTextOrd(ordText);
    console.log(`Botão "Ações" clicado para a linha com ID ${id}`);
  };

  const columns = [
    
    { field: "order_text", headerName: "Número da OS", width: 140 },
    { field: "date_os", headerName: "Data Criação", width: 140 },
    { field: "farmName", headerName: "Fazenda", width: 200 },
    { field: "county", headerName: "Município", width: 150 },
    { field: "federativeUnit", headerName: "UF", width: 50 },
    { field: "nameResponsible", headerName: "Responsável", width: 200 },
    { field: "data_update", headerName: "Data de Status", width: 140 },
    { field: "data_mogai", headerName: "Data de Mogai", width: 140 },
    {
        field: "actions",
        headerName: "Editar",
        width: 60,
        renderCell: (params) => (
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modal1"  onClick={() => handleEditOs(params.row.id_order, params.row.order_text)} variant="contained" color="primary">
          <ContentPasteGoIcon/>
            </button>
        ),
      },
      {
        field: "relatorio",
        headerName: "Anexar",
        width: 60,
        renderCell: (params) => (
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalmogai"  onClick={() => handleAnexar(params.row.id_order, params.row.order_text)} variant="contained" color="primary">
          <AddPhotoAlternateIcon/>
            </button>
        ),
      },
      {
        field: "arquivo",
        headerName: "Gerar",
        width: 60,
        renderCell: (params) => (
        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalstatus"  onClick={() => handleGerar(params.row.id_order)} variant="contained" color="primary">
          <AttachmentIcon/>
            </button>
        ),
      },
  ];

  const rows = data.map((row,index) => ({
    id: index +1 ,
    id_order: row.id_order,
    order_text: row.order_text,
    date_os: row.date_os,
    farmName: row.farmName,
    county: row.county,
    federativeUnit: row.federativeUnit,
    nameResponsible: row.nameResponsible,
    data_update: row.data_update,
    data_mogai: row.data_mogai,
    actiona: row.actions,
  }));
 
  return (
    <>
    <Modal1 
    ord={idOrder}
    ></Modal1>

    <ModalMogai 
    ord={idOrder}
    textOrd={textOrd}
    ></ModalMogai>
    
    <EditStatus
    ord={idOrder}
    ></EditStatus>
      
      <ModalNav/>
      <form className="box-center">
        <div className="my-3 transparent-input1" style={{ height: 500, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
        <div><hr class="divider bg-custom"/></div>
        <div class="col-md-12">
        <button type="button" class="btn btn-success" onClick={() => fetchDownloadResume()}>Exportar Arquivo</button>
        </div>
      </form>
      
      
    </>
  );
};

export default ListaOs;
