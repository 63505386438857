import { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/login_page/login';
import Home from '../pages/home_page';
import Cad_Os from '../pages/cadastro_os/cad_os';
import ListaOs from '../pages/cadastro_os/lista_os';


const Private = ({ Item }) => {
  const token = localStorage.getItem('token');
  if (token !== null) {
    return <Item />;
  } else {
    return <Navigate to="/login" />;
  }
};

export const LANGUAGES = {
  pt: {
    urlLang: 'pt',
    code: 'pt-BR'
  },
  en: {
    urlLang: 'en',
    code: 'en-US'
  },
  default: 'pt'
}

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route exactpath="/" element={<><Private Item={Home} /></>} />
          <Route path="/home" element={<><Private Item={Home} /></>} />
          <Route path="/ordens_de_servico" element={<><Private Item={ListaOs} /></>} />
          <Route path="/cadastro_os" element={<><Private Item={Cad_Os} /></>} />
          <Route path="*" element={<><Private Item={Home} /></>} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Fragment>

    </BrowserRouter>
  );
};

export default RoutesApp;