import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../style/style.css';
import { Search } from "@mui/icons-material";

import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

const Modal1 = ({ onClose, ord }) => {
  const [cadastros, setCadastros] = useState([]);
    const [Users, setUser] = useState([]);
    const [selectUser, setSelectUser] = useState()
    const [data, setData] = useState({
        "id_order": "",
        "farmName": "",
        "nameResponsible": "",
        "registerFarm": "",
        "county": "",
        "federativeUnit": "",
        "farmAndress": "",
        "telContact": "",
        "emailContact": "",
        "user_id": "",
        "rci_rti": "",
        "number_bayer": "",
        "vol_declarete": "",
        "data_mogai": "",
        "data_liberty": ""
    });
    const parsedURL = localStorage.getItem('urlParsed');
    const token  = localStorage.getItem('token');


    useEffect(() => {
      fetchData();
      fetchUsers();
  }, [ord]);

  const fetchUsers = async () => {
      try {
          const response = await axios.get(`${parsedURL}get/lista_usuarios/site`, {
              headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          });
          setUser(response.data);
      } catch (error) {
          console.error('Ocorreu um erro ao buscar os dados:', error);
      }
  };


  const fetchData = async () => {
      try {
          const response = await axios.get(`${parsedURL}get/lista_ordens_specific/site?orderNumber=${ord}`, {
              headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
          });
          console.log(response.data);

          if (response.data && response.data.length > 0) {
              setData(response.data[0]);
          }
      } catch (error) {
          console.error('Ocorreu um erro ao buscar os dados:', error);
      }
  };


  const fechUpdate = (e) => {
      setData({
          ...data,
          [e.target.name]: e.target.value,
      });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          const url = `${parsedURL}put/modificar_ordem`;
          const selectCLI = selectUser;

          const headers = {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            };

          const response = await axios.put(
              url,
              {
                  id_order: data.id_order,
                  farmName: data.farmName,
                  nameResponsible: data.nameResponsible,
                  registerFarm: data.registerFarm,
                  county: data.county,
                  federativeUnit: data.federativeUnit,
                  farmAndress: data.farmAndress,
                  telContact: data.telContact,
                  emailContact: data.emailContact,
                  user_id: data.user_id,
                  rci_rti: data.rci_rti,
                  number_bayer: data.number_bayer,
                  vol_declarete: data.vol_declarete,
              },
              { headers }
          );

          const responseData = response.data;
          // Define a lógica para o que você quer fazer após enviar os dados para a API.
          const toastError = document.getElementById('liveSucceful');
          const toastBody = toastError.querySelector('.toast-body');
          const toastBootstrap = new bootstrap.Toast(toastError);
      
          // Atualize o texto do toast com a mensagem de erro
          toastBody.textContent = 'OS editada com sucesso!';
          toastBootstrap.show();
      } catch (error) {
          console.error('Erro ao criar OS:', error);
          const toastError = document.getElementById('liveError');
          const toastBody = toastError.querySelector('.toast-body');
          const toastBootstrap = new bootstrap.Toast(toastError);
      
          // Atualize o texto do toast com a mensagem de erro
          toastBody.textContent = error.message || 'Erro desconhecido ao enviar a foto';
          toastBootstrap.show();
      }
  };

  return (
        <>
        <div class="modal fade" id="modal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 hfx" id="staticBackdropLabel">Editar Ordem de Serviço</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form class="row g-3">
      <p className='hfx'>Ordem de Serviço: {data.order_text}</p>
                  <div class="col-md-4">
                      <label for="razaosocial" class="form-label ">Fazenda</label>
                      <input type="text" name="farmName"class="form-control transparent-input" id="razaosocial"  value={data.farmName || ''}/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Agricultor</label>
                      <input type="text" name="nameResponsible" class="form-control transparent-input " id="nomefantasia" value={data.nameResponsible || ''}  onChange={(e) => setData({ ...data, nameResponsible: e.target.value })}/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">CNPJ</label>
                      <input type="text" name="registerFarm" class="form-control transparent-input " id="nomefantasia" value={data.registerFarm || ''} onChange={(e) => setData({ ...data, registerFarm: e.target.value })}/>
                  </div>
                  <div class="col-md-3">
                      <label for="razaosocial" class="form-label ">Municipio</label>
                      <input type="text" name="county" class="form-control transparent-input" id="razaosocial" value={data.county || ''} onChange={(e) => setData({ ...data, county: e.target.value })}/>
                  </div>
                  <div class="col-md-2">
                      <label for="nomefantasia" class="form-label">Estado</label>
                      <input type="text" name="federativeUnit" class="form-control transparent-input " id="nomefantasia" value={data.federativeUnit || ''} onChange={(e) => setData({ ...data, federativeUnit: e.target.value })}/>
                  </div>
                  <div class="col-md-7">
                      <label for="nomefantasia" class="form-label">Endereço</label>
                      <input type="text" name="farmAndress" class="form-control transparent-input " id="nomefantasia" value={data.farmAndress || ''} onChange={(e) => setData({ ...data, farmAndress: e.target.value })}/>
                  </div>
                  <div class="col-md-6">
                      <label for="razaosocial" class="form-label ">RCI/RTI</label>
                      <input type="text" name="ie" class="form-control transparent-input" id="razaosocial" value={data.rci_rti || ''} onChange={(e) => setData({ ...data, rci_rti: e.target.value })}/>
                  </div>
                  <div class="col-md-6">
                      <label for="nomefantasia" class="form-label">N° Bayer</label>
                      <input type="text" name="ie" class="form-control transparent-input " id="nomefantasia" value={data.number_bayer || ''}  onChange={(e) => setData({ ...data, number_bayer: e.target.value })}/>
                  </div>
                  <div><hr class="divider bg-custom"/></div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Usuário</label>
                      <select className=' form-select transparent-input'
                            value={data.user_id}
                            onChange={e => setData({ ...data, user_id: e.target.value })}
                            >
                            <option value="">Selecione o usuário</option>
                            {Users.length > 0 &&
                                        Users.map((user) => (
                                            <option key={user.id_user} value={user.id_user}>
                                                {user.name}
                                            </option>
                                ))
                            }
                            </select>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Telefone Contato</label>
                      <input type="text" name="telContact" class="form-control transparent-input " id="nomefantasia" value={data.telContact || ''} onChange={(e) => setData({ ...data, telContact: e.target.value })}/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">E-mail</label>
                      <input type="text" name="emailContact"  class="form-control transparent-input " id="nomefantasia" value={data.emailContact} onChange={(e) => setData({ ...data, emailContact: e.target.value })}/>
                  </div>
                  <div class="col-md-4">
                      <label for="nomefantasia" class="form-label">Data Criação</label>
                      <input type="text" name="date_os" class="form-control transparent-input " id="nomefantasia" value={data.date_os || ''} readOnly='true'/>
                  </div>
                  </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success"  onClick={handleSubmit}>Ok</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onClose}>Close</button>
      </div>
    </div>
  </div>
</div>

        </>
  );
};

export default Modal1;