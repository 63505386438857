import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../style/style.css";
import Logo_AGS from '../assets/images/AGS_L_BACK.png';
import Backgroud from '../assets/images/wallpaperflare.jpg';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const parsedURL = localStorage.getItem('urlParsed');
    const token  = localStorage.getItem('token');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            const resposta = await axios.post(
                `${parsedURL}post/login`,
                {
                    user: username,
                    password: password
                },
                { headers }
            );
            const data = resposta.data.user_info;
            const token = resposta.data.token; 
            console.log(data);
            if (data.active === 1) {
                localStorage.setItem('token', token);
                localStorage.setItem('typeUser', data.type_user);
                
                navigate('/home');
            } else {

                const toastError = document.getElementById('liveError');
                const toastBody = toastError.querySelector('.toast-body');
                const toastBootstrap = new bootstrap.Toast(toastError);
            
                // Atualize o texto do toast com a mensagem de erro
                toastBody.textContent =  'Usuario Bloqueado!'
                toastBootstrap.show();
          
            }
        } catch (error) {

            const toastError = document.getElementById('liveError');
            const toastBody = toastError.querySelector('.toast-body');
            const toastBootstrap = new bootstrap.Toast(toastError);
        
            // Atualize o texto do toast com a mensagem de erro
            toastBody.textContent =  'Usuario ou senha incorretos'
            toastBootstrap.show();
        }
    };

    return (
        <section className="section_login">
            <div className="container-login">
                <div className="img-box">
                    <img src={Backgroud} alt="Background" />
                </div>
                <div className="content-box">
                    <div className="form-box">
                        <div className="logo_">
                            <img src={Logo_AGS} className="imagem-logo" alt="Logo" />

                        </div>
                        <h2>Login</h2>
                        <ul>
                            <div className="input-box">
                                <span>Login</span>
                                <input type="email" placeholder="username"
                                onChange={(e) => setUsername(e.target.value)}
                                 value={username} />
                            </div>

                            <div className="input-box">
                                <span>Password</span>
                                <input type="password" placeholder="password"
                                onChange={e => setPassword(e.target.value)}
                                    value={password} />
                            </div>

                            <div className="remember">
                                <label>
                                    <input type="checkbox" /> Lembrar senha
                                </label>
                                <a href="#">Esqueceu a Senha?</a>
                            </div>

                            <div className="input-box">
                                <input onClick={handleLogin} type="submit" value="Entrar" />
                            </div>
                            <a className="footer-bar">Todos os direitos reservados para AG SURVEYORS ANALISES E TESTES FISICOS LTDA. CNPJ – 26.402.118/0001-30</a>
                        </ul>
                    </div>
                </div>
                <div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveError" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-danger text-white">
      <strong class="me-auto">Erro de Login</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-warning ">
    </div>
  </div>
</div>
                
            </div>
        </section>
    );
};

export default Login;
